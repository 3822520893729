// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainLayout_pageBG__RIO\\+c {\n  background-repeat: no-repeat;\n  background-position: center;\n  background-attachment: fixed;\n  margin-top: 80px;\n  background-size: 350px;\n}", "",{"version":3,"sources":["webpack://./src/styles/mainLayout.module.scss"],"names":[],"mappings":"AAEA;EACE,4BAAA;EACA,2BAAA;EACA,4BAAA;EACA,gBAAA;EAEA,sBAAA;AAFF","sourcesContent":["@import \"../variables.scss\";\n\n.pageBG {\n  background-repeat: no-repeat;\n  background-position: center;\n  background-attachment: fixed;\n  margin-top: 80px;\n  //   background-color: yellow;\n  background-size: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageBG": "mainLayout_pageBG__RIO+c"
};
export default ___CSS_LOADER_EXPORT___;
