// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".err404_dbErr404Container__KW\\+9L {\n  text-align: center;\n  min-height: calc(100vh - 100px);\n}\n\n.err404_dbErr404Header__Rze0k {\n  background-color: #2c3e50;\n  margin-bottom: 15px;\n}\n\n.err404_db-err404-header__PYF3D span {\n  font-size: 34px;\n  font-weight: bold;\n  display: inline-block;\n  padding: 5px 0;\n}\n\n.err404_dbErr404Content__SFu2v {\n  margin-top: 100px;\n}\n\n.err404_dbErr404Content__SFu2v img {\n  max-width: calc(100vw - 80%);\n}\n\n.err404_dbErr404Content__SFu2v p {\n  margin-top: 20px;\n  font-size: 26px;\n  color: rgb(75, 75, 75);\n}", "",{"version":3,"sources":["webpack://./src/styles/err404.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,+BAAA;AACF;;AAGA;EACE,yBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,4BAAA;AAAF;;AAGA;EACE,gBAAA;EACA,eAAA;EACA,sBAAA;AAAF","sourcesContent":[".dbErr404Container {\n  text-align: center;\n  min-height: calc(100vh - 100px);\n  //   background-image: url(./bg1.png);\n}\n\n.dbErr404Header {\n  background-color: #2c3e50;\n  margin-bottom: 15px;\n}\n\n.db-err404-header span {\n  font-size: 34px;\n  font-weight: bold;\n  display: inline-block;\n  padding: 5px 0;\n}\n\n.dbErr404Content {\n  margin-top: 100px;\n}\n\n.dbErr404Content img {\n  max-width: calc(100vw - 80%);\n}\n\n.dbErr404Content p {\n  margin-top: 20px;\n  font-size: 26px;\n  color: rgba(75, 75, 75, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dbErr404Container": "err404_dbErr404Container__KW+9L",
	"dbErr404Header": "err404_dbErr404Header__Rze0k",
	"db-err404-header": "err404_db-err404-header__PYF3D",
	"dbErr404Content": "err404_dbErr404Content__SFu2v"
};
export default ___CSS_LOADER_EXPORT___;
